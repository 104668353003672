<template>
  <el-dialog
    class="box"
    :title="'编辑规则'"
    :visible.sync="ruleVisible"
    width="fit-content"
    v-loading="loading"
    @closed="close"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleForm"
      label-width="80px"
      v-if="ruleVisible"
      :model="dataForm"
    >
      <el-form-item
        label="规则说明"
        required
        prop="desc"
        id="quillEditorQiniu"
        :rules="[
          {
            required: true,
            message: '请输入规则说明',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.desc"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item
        id="url"
        label="推文链接"
        required
        prop="url"
        style="margin-bottom: 10px"
        :rules="[
          {
            required: true,
            message: '请输入推文链接',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <el-input
          ref="name"
          v-model="dataForm.url"
          placeholder="请输入推文链接"
          show-word-limit
        ></el-input>
      </el-form-item>
    
      <div style="width: 100%; text-align: center; margin-top: 35px">
        <el-button type="primary" @click="saveRule">确定</el-button>
        <el-button @click="ruleVisible = false" style="margin-left: 20px"
          >取消</el-button
        >
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { uploadOssUrl } from '@/utils/uploadUrl'
import { randomStr } from '@/utils/randomStr'
import { mapActions } from 'vuex'
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ indent: '-1' }, { indent: '+1' }],
  [{ size: ['20rpx', '24rpx', '26rpx', '32rpx', '48rpx', 'custom-size'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    {
      color: [
        '#000000',
        '#e60000',
        '#ff9900',
        '#ffff00',
        '#008a00',
        '#0066cc',
        '#9933ff',
        '#ffffff',
        '#facccc',
        '#ffebcc',
        '#ffffcc',
        '#cce8cc',
        '#cce0f5',
        '#ebd6ff',
        '#bbbbbb',
        '#f06666',
        '#ffc266',
        '#ffff66',
        '#66b966',
        '#66a3e0',
        '#c285ff',
        '#888888',
        '#a10000',
        '#b26b00',
        '#b2b200',
        '#006100',
        '#0047b2',
        '#6b24b2',
        '#444444',
        '#5c0000',
        '#663d00',
        '#666600',
        '#003700',
        '#002966',
        '#3d1466',
        'custom-color',
      ],
    },
    { background: [] },
  ], // dropdown with defaults from theme
  [{ align: [] }],
  ['image'],
  ['clean'], // remove formatting button
]
export default {
  name: 'ruleManager',
  emits: ['onClose'],
  props: {
    type: {
      type: String,
    },
     wxrule: {
      type: Boolean,
    },
  },
  data() {
    return {
      ruleVisible: false,
      loading: false,
      dataForm: {
        avatar: '',
        desc: '',
        url: '',
      },
      editorOption: {
        placeholder: '请填写规则',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === 'custom-size') {
                  value = prompt('请输入字体大小')
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      '请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备'
                    )
                    value = ''
                  }
                }

                this.quill.format('size', value)
              },
              color: function(value) {
                if (value === 'custom-color') {
                  value = prompt('请输入十六进制的颜色')
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert('请输入合法的十六进制颜色')
                    value = ''
                  }
                }

                this.quill.format('color', value)
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector('#quillEditorQiniu .avatar-uploader input')
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector('#quillEditorQiniu .avatar-uploader input')
                    .click()
                } else {
                  this.quill.format('video', false)
                }
              },
            },
          },
        },
      },
    }
  },
  methods: {
    close() {
      this.$emit('onClose')
    },
      beforeTopImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }

      return isJPG && isLt2M
    },
        async httpUploadRequest(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().sect + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      console.log("🚀 ~ httpUploadRequest ~ result:", result)
      if (result) {
        this.dataForm.avatar = result
      } else {
        this.$message.error('上传失败')
      }
      this.loading = false
    },
    async saveRule() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let { data, errorCode } = await this.$http.armory.saveRule(
            this.type,
            { ...this.dataForm }
          )
          if (errorCode != '0000') {
            this.$message.error('保存失败')
            return
          }

          this.$message({
            type: 'success',
            message: '保存成功',
          })

          this.close()
          this.loading = false
        }
      })
    },
    async loadRule() {
      this.loading = true
      let { data, errorCode } = await this.$http.armory.detailRule(this.type)
      if (errorCode != '0000') {
        return
      }

      if (data) {
        this.dataForm.desc = data.desc
        this.dataForm.url = data.url
      }

      this.ruleVisible = true
      this.loading = false
    },
    async httpUploadEditorRequest(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().sect + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        console.log('上传成功')
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result
        let type = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
        console.log(type)
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == 'mp4' || type == 'MP4') {
          quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.insertEmbed(length, 'image', imgUrl)
        }
        // 调整光标到最后
        quill.setSelection(length + 1)
        //取消上传动画
        this.quillUpdateImg = false
      } else {
        this.$message.error('上传失败')
      }

      this.loading = false
    },
  },
  created() {
    this.loadRule()
  },
}
</script>

<style lang="less" scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 400px;
}

.desc_editor {
  line-height: normal !important;
  height: 200px;
  width: 100%;
  margin-bottom: 90px;
}
.prize_editor {
  line-height: normal !important;
  height: 200px;
  width: 100%;
  margin-bottom: 90px;
}
/deep/ .ql-snow .ql-tooltip[data-mode='link']::before {
  content: '请输入链接地址:';
}
/deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '保存';
  padding-right: 0px;
}

/deep/ .ql-snow .ql-tooltip[data-mode='video']::before {
  content: '请输入视频地址:';
}

/deep/ .ql-editor .ql-size-10px {
  font-size: 10px;
}
/deep/ .ql-editor .ql-size-11px {
  font-size: 11px;
}
/deep/ .ql-editor .ql-size-12px {
  font-size: 12px;
}
/deep/ .ql-editor .ql-size-13px {
  font-size: 13px;
}
/deep/ .ql-editor .ql-size-14px {
  font-size: 14px;
}
/deep/ .ql-editor .ql-size-15px {
  font-size: 15px;
}
/deep/ .ql-editor .ql-size-16px {
  font-size: 16px;
}
/deep/ .ql-editor .ql-size-17px {
  font-size: 17px;
}
/deep/ .ql-editor .ql-size-18px {
  font-size: 18px;
}
/deep/ .ql-editor .ql-size-19px {
  font-size: 19px;
}
/deep/ .ql-editor .ql-size-20px {
  font-size: 20px;
}
/deep/ .ql-editor .ql-size-21px {
  font-size: 21px;
}
/deep/ .ql-editor .ql-size-22px {
  font-size: 22px;
}
/deep/ .ql-editor .ql-size-23px {
  font-size: 23px;
}
/deep/ .ql-editor .ql-size-24px {
  font-size: 24px;
}
/deep/ .ql-editor .ql-size-25px {
  font-size: 25px;
}
/deep/ .ql-editor .ql-size-26px {
  font-size: 26px;
}
/deep/ .ql-editor .ql-size-27px {
  font-size: 27px;
}
/deep/ .ql-editor .ql-size-28px {
  font-size: 28px;
}
/deep/ .ql-editor .ql-size-29px {
  font-size: 29px;
}
/deep/ .ql-editor .ql-size-30px {
  font-size: 30px;
}
/deep/ .ql-editor .ql-size-31px {
  font-size: 31px;
}
/deep/ .ql-editor .ql-size-32px {
  font-size: 32px;
}
/deep/ .ql-editor .ql-size-33px {
  font-size: 33px;
}
/deep/ .ql-editor .ql-size-34px {
  font-size: 34px;
}
/deep/ .ql-editor .ql-size-35px {
  font-size: 35px;
}
/deep/ .ql-editor .ql-size-36px {
  font-size: 36px;
}
/deep/ .ql-editor .ql-size-37px {
  font-size: 37px;
}
/deep/ .ql-editor .ql-size-38px {
  font-size: 38px;
}
/deep/ .ql-editor .ql-size-39px {
  font-size: 39px;
}
/deep/ .ql-editor .ql-size-40px {
  font-size: 40px;
}
/deep/ .ql-editor .ql-size-41px {
  font-size: 41px;
}
/deep/ .ql-editor .ql-size-42px {
  font-size: 42px;
}
/deep/ .ql-editor .ql-size-43px {
  font-size: 43px;
}
/deep/ .ql-editor .ql-size-44px {
  font-size: 44px;
}
/deep/ .ql-editor .ql-size-45px {
  font-size: 45px;
}
/deep/ .ql-editor .ql-size-46px {
  font-size: 46px;
}
/deep/ .ql-editor .ql-size-47px {
  font-size: 47px;
}
/deep/ .ql-editor .ql-size-48px {
  font-size: 48px;
}
/deep/ .ql-editor .ql-size-49px {
  font-size: 49px;
}
/deep/ .ql-editor .ql-size-50px {
  font-size: 50px;
}

/deep/ .ql-editor .ql-size-18rpx {
  font-size: 9px;
}
/deep/ .ql-editor .ql-size-19rpx {
  font-size: 9px;
}
/deep/ .ql-editor .ql-size-20rpx {
  font-size: 10px;
}
/deep/ .ql-editor .ql-size-21rpx {
  font-size: 10px;
}
/deep/ .ql-editor .ql-size-22rpx {
  font-size: 11px;
}
/deep/ .ql-editor .ql-size-23rpx {
  font-size: 11px;
}
/deep/ .ql-editor .ql-size-24rpx {
  font-size: 12px;
}
/deep/ .ql-editor .ql-size-25rpx {
  font-size: 12px;
}
/deep/ .ql-editor .ql-size-26rpx {
  font-size: 13px;
}
/deep/ .ql-editor .ql-size-27rpx {
  font-size: 13px;
}
/deep/ .ql-editor .ql-size-28rpx {
  font-size: 14px;
}
/deep/ .ql-editor .ql-size-29rpx {
  font-size: 14px;
}
/deep/ .ql-editor .ql-size-30rpx {
  font-size: 15px;
}
/deep/ .ql-editor .ql-size-31rpx {
  font-size: 15px;
}
/deep/ .ql-editor .ql-size-32rpx {
  font-size: 16px;
}
/deep/ .ql-editor .ql-size-33rpx {
  font-size: 16px;
}
/deep/ .ql-editor .ql-size-34rpx {
  font-size: 17px;
}
/deep/ .ql-editor .ql-size-35rpx {
  font-size: 17px;
}
/deep/ .ql-editor .ql-size-36rpx {
  font-size: 18px;
}
/deep/ .ql-editor .ql-size-37rpx {
  font-size: 18px;
}
/deep/ .ql-editor .ql-size-38rpx {
  font-size: 19px;
}
/deep/ .ql-editor .ql-size-39rpx {
  font-size: 19px;
}
/deep/ .ql-editor .ql-size-40rpx {
  font-size: 20px;
}
/deep/ .ql-editor .ql-size-41rpx {
  font-size: 20px;
}
/deep/ .ql-editor .ql-size-42rpx {
  font-size: 21px;
}
/deep/ .ql-editor .ql-size-43rpx {
  font-size: 21px;
}
/deep/ .ql-editor .ql-size-44rpx {
  font-size: 22px;
}
/deep/ .ql-editor .ql-size-45rpx {
  font-size: 22px;
}
/deep/ .ql-editor .ql-size-46rpx {
  font-size: 23px;
}
/deep/ .ql-editor .ql-size-47rpx {
  font-size: 23px;
}
/deep/ .ql-editor .ql-size-48rpx {
  font-size: 24px;
}
/deep/ .ql-editor .ql-size-49rpx {
  font-size: 24px;
}
/deep/ .ql-editor .ql-size-50rpx {
  font-size: 25px;
}
/deep/ .ql-editor .ql-size-51rpx {
  font-size: 25px;
}
/deep/ .ql-editor .ql-size-52rpx {
  font-size: 26px;
}
/deep/ .ql-editor .ql-size-53rpx {
  font-size: 26px;
}
/deep/ .ql-editor .ql-size-54rpx {
  font-size: 27px;
}
/deep/ .ql-editor .ql-size-55rpx {
  font-size: 27px;
}
/deep/ .ql-editor .ql-size-56rpx {
  font-size: 28px;
}
/deep/ .ql-editor .ql-size-57rpx {
  font-size: 28px;
}
/deep/ .ql-editor .ql-size-58rpx {
  font-size: 29px;
}
/deep/ .ql-editor .ql-size-59rpx {
  font-size: 29px;
}
/deep/ .ql-editor .ql-size-60rpx {
  font-size: 30px;
}
/deep/ .ql-editor .ql-size-61rpx {
  font-size: 30px;
}
/deep/ .ql-editor .ql-size-62rpx {
  font-size: 31px;
}
/deep/ .ql-editor .ql-size-63rpx {
  font-size: 31px;
}
/deep/ .ql-editor .ql-size-64rpx {
  font-size: 32px;
}
/deep/ .ql-editor .ql-size-65rpx {
  font-size: 32px;
}
/deep/ .ql-editor .ql-size-66rpx {
  font-size: 33px;
}
/deep/ .ql-editor .ql-size-67rpx {
  font-size: 33px;
}
/deep/ .ql-editor .ql-size-68rpx {
  font-size: 34px;
}
/deep/ .ql-editor .ql-size-69rpx {
  font-size: 34px;
}
/deep/ .ql-editor .ql-size-70rpx {
  font-size: 35px;
}
/deep/ .ql-editor .ql-size-71rpx {
  font-size: 35px;
}
/deep/ .ql-editor .ql-size-72rpx {
  font-size: 36px;
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='26px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='26px']::before {
  content: '26px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
  content: '32px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
  content: '48px';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20rpx']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20rpx']::before {
  content: '20rpx';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24rpx']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24rpx']::before {
  content: '24rpx';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='26rpx']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='26rpx']::before {
  content: '26rpx';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32rpx']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32rpx']::before {
  content: '32rpx';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48rpx']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48rpx']::before {
  content: '48rpx';
}

/deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value='custom-size']::before,
/deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value='custom-size']::before {
  content: '自定义';
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: '10px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: '18px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: '32px';
}

/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '标题1';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '标题2';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '标题3';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '标题4';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '标题5';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '标题6';
}

/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体';
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: '衬线字体';
}
/deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='monospace']::before,
/deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='monospace']::before {
  content: '等宽字体';
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .ql-picker-item[data-value='custom-color'] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value='custom-color']::before {
  content: '自定义颜色';
  color: black;
  width: fit-content;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
  padding-top: 0;
  height: 680px;
}

.box {
  transform: translateY(-50px);
}

// .dataForm_avatar {

// }
// .dataForm_upload {
//      width: 100px;
//   height: 100px;
//   overflow: hidden;
// }
// /deep/ .el-upload {
//   width: 100px;
//   height: 100px;
//   overflow: hidden;
// }
// /deep/ .el-upload-dragger {
//   width: 100px;
//   height: 100px;
//   overflow: hidden;
// }
// /deep/ .el-icon-upload {
//   width: 40px;
//   height: 40px;
//    transform: scale(0.5);
//    margin: 0;
// margin-top: 10px;
//    margin-left:  -7px;
// }
// /deep/ .el-upload__text {
//   width: 150px;
//   height: 40px;
//    transform: scale(0.6);
//    margin-top: -15px;
//    margin-left: -25px;
// }
// .avatar {
//   width: 100px;
//   height: 100px;
//   overflow: hidden;
// }

</style>
